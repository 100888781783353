<template>
  <div>
    <mail-template-list></mail-template-list>
  </div>
</template>

<script>

export default {
}
</script>


